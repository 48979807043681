<template>
    <div>
        <vs-tooltip v-model="isOpen" border>
            <vs-button circle icon floating @click="redirectTo">
                {{ icon }}
            </vs-button>
            <template #tooltip> {{ message }} </template>
        </vs-tooltip>
    </div>
</template>

<script>
export default {
    name: "TooltipRedirect",
    props: {
        to: { default: "/app", type: String, required: true },
        message: { default: "Nuevo", type: String, required: true },
        icon: { default: "➕", type: String, required: false }
    },
    data: () => ({ isOpen: false, enabledRedirect: false }),
    methods: {
        redirectTo() {
            if (this.isOpen) {
                this.isOpen = false;
                setTimeout(() => this.$router.push(this.to), 100);
            } else {
                this.isOpen = true;
                setTimeout(() => (this.isOpen = false), 5000);
            }
        }
    }
};
</script>
